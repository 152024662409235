<template>
    <div>
        <ul class="ul" ref="first">
            <!-- <li class="list" :class="{'mv':mInfo.mi==i}" v-for="(m,i) in list" @touchstart="tm($event, i,m);" @touchmove="tm($event, i,m, 1);" @touchend="tm($event, i,m, 2);">{{m.num}}</li> -->
            <li class="list" draggable="true" :class="{'mv':mInfo.mi==i}" v-for="(m,i) in list" @dragstart="test($event,i,m);" @drag="test($event,i,m, 1)" @dragend="test($event,i,m, 2);">{{m.num}}</li>
            <li class="list move" v-show="mInfo.mi!=-1" ref="move">{{mInfo.val.num}}</li>
            <!-- <li class="list" draggable="true" :class="{'mv':mInfo.mi==i}" v-for="(m,i) in list" @mousedown="test1($event, m,i)" @mousemove="test1($event, m,i,1)" @mouseup="test1($event, m,i,2)">{{m.num}}</li>
            <li class="list move" @dragstart="test($event);" @drag="test($event, 1)" @dragend="test($event, 2);" v-show="mInfo.mi!=-1" ref="move">{{mInfo.val.num}}</li> -->
        </ul>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                list: [],
                mInfo: {
                    mi: -1,
                    val: {},
                    x1: 0, // 初始x
                    y1: 0, // 初始y
                    x2: 0, // 当前x
                    y2: 0, // 当前y
                    top: 0,
                    num: 0,
                },
            };
        },
        created() {

        },
        mounted() {
            // 在组件被挂载之后调用。
            this.getData();
            this.mInfo.top = this.$refs.first.offsetTop;
        },
        methods: {
            // 监听移动
            test(event, i, m, type) {
                var _this = this;
                if (i < _this.mInfo.min) return;
                var x = event.clientX;
                var y = event.clientY;
                var e = event.currentTarget;
                var w = e.offsetWidth + 10;
                var h = e.offsetHeight + 10;
                var $move = _this.$refs.move;
                // var $move = e;
                if (!type) {
                    _this.mInfo.val = m;
                    _this.mInfo.mi = i;
                    _this.mInfo.x1 = x - e.offsetLeft; // 鼠标在元素内的位置
                    _this.mInfo.y1 = y - e.offsetTop - _this.mInfo.top;  // 鼠标在元素内的位置
                    _this.mInfo.x2 = x;
                    _this.mInfo.y2 = y;
                    $move.style.left = e.offsetLeft + 'px';
                    $move.style.top = (y - _this.mInfo.y1) + 'px';
                } else {
                    let num = Math.floor((_this.mInfo.y2 - _this.mInfo.top) / h);
                    if (num < _this.mInfo.min) {
                        num = _this.mInfo.min;
                    } else if (num >= _this.list.length) {
                        num = _this.list.length - 1;
                    }
                    if (type != 2) {
                        _this.mInfo.num = num;
                    } else {
                        num = _this.mInfo.num
                    }
                    _this.rmList(_this.mInfo.mi, num);
                    _this.mInfo.mi = num;
                    _this.mInfo.x2 = x;
                    _this.mInfo.y2 = y;
                    $move.style.left = (x - _this.mInfo.x1) + 'px';
                    $move.style.top = (y - _this.mInfo.y1) + 'px';
                    if (type == 2) {
                        _this.mInfo.mi = -1;
                        _this.mInfo.move = -1;
                    }
                }
            },
            // 重排位置
            rmList(s, e) {
                var _this = this;
                var b = _this.list.splice(s, 1);
                _this.list.splice(e, 0, b[0]);
            },
            getData() {
                var arr = [];
                for (var i = 0; i < 5; i++) {
                    arr.push({
                        num: i
                    })
                    this.list = arr;
                }
            }
        },
        computed: {
            // 计算属性
        },
        watch: {

        },
    };
</script>

<style lang="less" scoped>
    .ul {
        position: relative;
        margin: 100px 30px;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
    }

    .list {
        line-height: 80px;
        background: red;
        color: #fff;
        margin-bottom: 10px;
        list-style: none;
        width: 100%;
    }

    .list.mv {
        background: transparent;
        color: transparent;
        opacity: 0;
    }

    .move {
        position: fixed;
        width: calc(100vw - 60px);
        left: 0;
        box-shadow: 0 2px 5px 0px #333;
        line-height: 80px;
        z-index: -1;
        background: blue;
    }
</style>